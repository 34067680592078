import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import logo from "../assets/logo-lcf-1.webp";

const Modal = ({ type, closeModal, handleLogin }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    if (!formData.username || !formData.password || (type === "register" && !formData.email)) {
      setError("Veuillez remplir tous les champs");
      setIsLoading(false);
      return;
    }

    try {
      if (type === "register") {
        await axios.post("https://api.lcflive.fr/api/auth/register", formData);
        setError("Inscription réussie. Vous pouvez maintenant vous connecter.");
        setTimeout(() => closeModal(), 2000);
      } else if (type === "login") {
        const response = await axios.post(
          "https://api.lcflive.fr/api/auth/login",
          {
            username: formData.username,
            password: formData.password,
          }
        );
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("username", formData.username);
        handleLogin(response.data.token);
        closeModal();
        navigate('/');
      }
    } catch (error) {
      console.error("Error:", error);
      setError(error.response?.data?.error || "Une erreur s'est produite");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modal" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>{type === "register" ? "S'inscrire" : "Se connecter"}</h2>
        <form onSubmit={handleSubmit}>
          <img src={logo} alt="Logo" />
          <input
            type="text"
            name="username"
            placeholder="Nom d'utilisateur"
            value={formData.username}
            onChange={handleChange}
            required
          />
          {type === "register" && (
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          )}
          <input
            type="password"
            name="password"
            placeholder="Mot de passe"
            value={formData.password}
            onChange={handleChange}
            required
          />
          {error && <p className="error-message">{error}</p>}
          <button type="submit" disabled={isLoading}>
            {isLoading ? "Chargement..." : "Envoyer"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Modal;
